<template>
  <div>
    <a @click="$router.back()" href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center">
      <i class="mdi mdi-chevron-left mr-2 mt-1"></i>
      Kembali
    </a>
    <validation-observer ref="formSiswa">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Buat Akun Siswa
              </span>
            </h3>
            <div class="card-toolbar">
              <button type="submit" class="btn btn-success font-weight-bolder font-size-sm mr-2">
                Simpan
              </button>
              <span @click="$router.back()" class="btn btn-secondary font-weight-bolder font-size-sm">
                Batal</span>
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group id="input-group-nik" label="NIK Siswa:" label-for="input-nik">
              <validation-provider #default="{ errors }" name="NIK Siswa" rules="required|numeric|length:16">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-nik"
                  v-model="currentUserForm.body.nik" type="text" placeholder="Masukkan NIK Siswa"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-nisn" label="NISN Siswa:" label-for="input-nisn">
              <validation-provider #default="{ errors }" name="NISN Siswa" rules="required|numeric|length:10">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-nisn"
                  v-model="currentUserForm.body.nisn" type="text" placeholder="Masukkan NISN Siswa"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-name" label="Nama Siswa:" label-for="input-name">
              <validation-provider #default="{ errors }" name="Nama Siswa" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-name"
                  v-model="currentUserForm.body.name" type="text" placeholder="Masukkan Nama Siswa"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-address" label="Alamat:" label-for="input-address">
              <validation-provider #default="{ errors }" name="Alamat" rules="required">
                <b-form-textarea :state="errors.length > 0 ? false : null" id="input-address"
                  v-model="currentUserForm.body.address" type="text" rows="3"
                  placeholder="Masukkan Alamat"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-coordinates" label="Koordinat Rumah (Sesuai KK):"
              label-for="input-coordinates">
              <validation-provider #default="{ errors }" name="Koordinat Rumah (Sesuai KK)" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-coordinates"
                  v-model="currentUserForm.body.coordinates" type="text"
                  placeholder="Masukkan Koordinat Rumah (Sesuai KK)"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="separator separator-solid mt-2"></div>
            <br />

            <b-form-group id="input-group-place" label="Tempat Lahir:" label-for="input-place">
              <validation-provider #default="{ errors }" name="Tempat Lahir" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-place"
                  v-model="currentUserForm.body.place" type="text" placeholder="Masukkan Tempat Lahir"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-birth" label="Tanggal Lahir:" label-for="input-birth">
              <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="required">
                <b-form-input :state="errors.length > 0 ? false : null" id="input-birth"
                  v-model="currentUserForm.body.birth" type="date" placeholder="Masukkan Tanggal Lahir"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-identification" label="Identifikasi Siswa:" label-for="input-identification">
              <validation-provider #default="{ errors }" name="Identifikasi Siswa" rules="required">
                <b-form-select id="input-identification" v-model="currentUserForm.body.student_identification"
                  :options="[{ value: 'R', text: 'Reguler' }, { value: 'I', text: 'Inklusi' }]"
                  placeholder="Pilih Identifikasi Siswa" size="md"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom card-stretch mt-8">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Nilai Rapor Siswa
              </span>
            </h3>
          </div>

          <div class="card-body py-4" v-for="(grade, g) in grades" :key="g">
            <h5 class="text-md mb-8">Kelas {{ grade }}</h5>
            <b-row class="m-0 bg-secondary">
              <b-col class="border border-1 pt-4 p-2">
                <h6>
                  Mata Pelajaran
                </h6>
              </b-col>
              <b-col class="border border-1 pt-4 p-2">
                <h6>
                  Semester Ganjil
                </h6>
              </b-col>
              <b-col class="border border-1 pt-4 p-2" v-if="grade != 6">
                <h6>
                  Semester Genap
                </h6>
              </b-col>
              <b-col v-else></b-col>
            </b-row>
            <b-row class="m-0" v-for="(course, c) in courses" :key="c">
              <b-col class="border border-1 p-2">
                {{ course }}
              </b-col>
              <b-col class="border border-1 p-2">
                <b-form-group :id="`group-nilai-${g}-${c}-ganjil`" label="" :label-for="`nilai-${g}-${c}-ganjil`">
                  <validation-provider #default="{ errors }" :name="`Nilai ${course} Kelas ${grade} Semester Ganjil`"
                    rules="required|numeric|min_value:0|max_value:100">
                    <b-form-input v-model="assessments[grade][course].ganjil" :state="errors.length > 0 ? false : null"
                      :id="`nilai-${g}-${c}-ganjil`" type="text" maxlength="3" min="0" max="100"
                      :placeholder="`Nilai ${course} Kelas ${grade} Semester Ganjil`"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="border border-1 p-2" v-if="grade != 6">
                <b-form-group :id="`group-nilai-${g}-${c}-genap`" label="" :label-for="`nilai-${g}-${c}-genap`">
                  <validation-provider #default="{ errors }" :name="`Nilai ${course} Kelas ${grade} Semester Genap`"
                    rules="required|numeric|min_value:0|max_value:100">
                    <b-form-input v-model="assessments[grade][course].genap" :state="errors.length > 0 ? false : null"
                      :id="`nilai-${g}-${c}-genap`" type="text" maxlength="3" min="0" max="100"
                      :placeholder="`Nilai ${course} Kelas ${grade} Semester Genap`"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-else></b-col>
            </b-row>
          </div>
          <br>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  ROLE_STUDENT,
  UPDATE_STUDENT,
} from "@/core/services/store/user.module";
import { REGISTER } from "@/core/services/store/auth.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  name: "SiswaForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      currentUserForm: {
        body: {
          role: ROLE_STUDENT,
        },
      },
      isLoading: false,
      editorOption: {
        // Some Quill options...
      },
      grades: [4, 5, 6],
      courses: [
        'Agama',
        'PPKn',
        'Bahasa Indonesia',
        'Matematika',
        'IPA',
        'IPS',
        'PJOK',
        'Bahasa Jawa',
        'Seni Budaya dan Prakarya',
      ],
      assessments: {
        '4': {
          'Agama': { ganjil: 0, genap: 0 },
          'Bahasa Indonesia': { ganjil: 0, genap: 0 },
          'Bahasa Jawa': { ganjil: 0, genap: 0 },
          'IPA': { ganjil: 0, genap: 0 },
          'IPS': { ganjil: 0, genap: 0 },
          'Matematika': { ganjil: 0, genap: 0 },
          'PJOK': { ganjil: 0, genap: 0 },
          'PPKn': { ganjil: 0, genap: 0 },
          'Seni Budaya dan Prakarya': { ganjil: 0, genap: 0 },
        },
        '5': {
          'Agama': { ganjil: 0, genap: 0 },
          'Bahasa Indonesia': { ganjil: 0, genap: 0 },
          'Bahasa Jawa': { ganjil: 0, genap: 0 },
          'IPA': { ganjil: 0, genap: 0 },
          'IPS': { ganjil: 0, genap: 0 },
          'Matematika': { ganjil: 0, genap: 0 },
          'PJOK': { ganjil: 0, genap: 0 },
          'PPKn': { ganjil: 0, genap: 0 },
          'Seni Budaya dan Prakarya': { ganjil: 0, genap: 0 },
        },
        '6': {
          'Agama': { ganjil: 0 },
          'Bahasa Indonesia': { ganjil: 0 },
          'Bahasa Jawa': { ganjil: 0 },
          'IPA': { ganjil: 0 },
          'IPS': { ganjil: 0 },
          'Matematika': { ganjil: 0 },
          'PJOK': { ganjil: 0 },
          'PPKn': { ganjil: 0 },
          'Seni Budaya dan Prakarya': { ganjil: 0 },
        },
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formSiswa.validate().then((success) => {
        this.currentUserForm.body.assessment = this.assessments
        this.isLoading = true;
        if (success) {
          if (this.studentId) {
            this.currentUserForm._id = this.currentUserSelected._id;
          } else {
            this.currentUserForm.role = 'student'
            // this.currentUserForm.vendor_id = 'student'
          }

          const _data = JSON.parse(JSON.stringify(this.currentUserForm));
          this.$store
            .dispatch(this.studentId ? UPDATE_STUDENT : REGISTER, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.studentId ? "mengubah" : "membuat"
                  } siswa`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/siswa`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.studentId ? "mengubah" : "membuat"} Siswa`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() { },
    validateAssessment(n) {
      console.log(n)
    }
  },
  mounted() {
    if (this.studentId) {
      const currentUser = this.currentUserSelected;
      console.log('currentUser', currentUser)

      if (currentUser.student.full_name === undefined) {
        this.$router.back();
      }

      this.currentUserForm = {
        body: {
          role: ROLE_STUDENT,
          nik: currentUser.student.nik,
          nisn: currentUser.student.nisn,
          coordinates: currentUser.student.coordinates,
          birth: currentUser.student.birth,
          place: currentUser.student.place,
          student_identification: currentUser.student.student_identification,
          name: currentUser.name,
          address: currentUser.student.address,
          no_induk: currentUser.student.no_induk,
          email: currentUser.email,
        },
      };

      this.assessments = JSON.parse(currentUser.student.assessment)
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUserSelected"]),
    studentId() {
      return this.$route.params.studentId;
    },
  },
};
</script>
